import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ZoopInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    
    const requestUrl: Array<any> = request.url.split('/');
    const apiUrl: Array<any> = environment.apiZoop.split('/');
    if((requestUrl[2] === apiUrl[2]) ){
      const newRequest = request.clone({
        setHeaders: {
          Authorization: `Basic enBrX3Byb2RfYWtDUlZSWXYxTTU3ejQ2eHRLb0szOEVFOg==`
        }
      });
      return next.handle(newRequest);
    }else{
      return next.handle(request);
    }

  }
}

//eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkYXNoIiwiZW1haWwiOiJsdWRtaWxsYS5kaWFzYWx2ZXNAaG90bWFpbC5jb20iLCJpYXQiOjE3MTM3MzE2NjgsImV4cCI6MTcxMzc2NDA2OCwicGF0aF92YWxpZGF0aW9ucyI6eyJtYXJrZXRwbGFjZXMiOlsiNzY5MjQwYzY1ZWJhNGJlNjlmZDRmNzVmZGU2ZGRmNjYiLCJmZWJhZTgwYjBlZDM0YTg2OTI5MzdjZDhkZGZjYzQzNiIsImE2NzAwYTg2ZDIxYTQzNTE4YTFmZmMwN2MzZDNhN2UyIl0sInNlbGxlcnMiOlsiKiJdfSwiYjJjX3Byb2ZpbGUiOiJmdWxsX2FjY2Vzc19hZG1pbiJ9.AeUfkpiSL6s9rLGBZqzIZMgP3QkUGT4yINrVGi4VmEk a6700a86d21a43518a1ffc07c3d3a7e2

/**enBrX3Byb2RfYWtDUlZSWXYxTTU3ejQ2eHRLb0szOEVFOg==    token de produção */

/**enBrX3Rlc3Rfa0NmVzBjaXJRbVAyWnNsaG9kOHc0WVJBOg==    Token de teste */

/** a6700a86d21a43518a1ffc07c3d3a7e2    marketplace de produção*/

/** 14d496f2a28444568e14ab66d84f65ce  marketplace de teste */

