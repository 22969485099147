import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Camera } from '@ionic-native/camera/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { AuthGuard } from './guards/auth.guard';
import { TokenInterceptor } from './helpers/token.interceptor';
import { ZoopInterceptor } from './helpers/zoop.interceptor';
import { RefreshTokenInterceptor } from './helpers/refresh-token.interceptor';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { NFC, Ndef } from '@awesome-cordova-plugins/nfc/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    AuthGuard,
    Camera,
    StatusBar,
	  BarcodeScanner,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS,   useClass: TokenInterceptor,   multi: true },
    { provide: HTTP_INTERCEPTORS,   useClass: ZoopInterceptor,    multi: true },
    { provide: HTTP_INTERCEPTORS,   useClass: RefreshTokenInterceptor,    multi: true },
     SocialSharing,
     NFC, 
     Ndef

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
