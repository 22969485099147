import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
 constructor(private authSrv: AuthService, private router: Router){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
      return next.handle(request).pipe(catchError((err: HttpErrorResponse)=>{
        if (err.status === 401){
          this.authSrv.logout();
          this.router.navigate(['/login'])

        }
        return Observable.throw(err)
      }));
    

  }
}
