import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Login } from '../models/login.model';
import { IndividualPerson } from '../models/individualperson.model';
import { User } from '../models/user.model';
import { LegalPerson } from '../models/legalperson.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  zoopUrl = 'https://api.zoop.ws/v1/marketplaces/a6700a86d21a43518a1ffc07c3d3a7e2/sellers/individuals';
  constructor(private http: HttpClient) { }

  check(): boolean {
    return localStorage.getItem('token') ? true : false
  }
  
  login(login: Login): Observable<Login> {
    return this.http.post<any>(`${environment.apiUrl}/auth/login`, login).pipe(map(data => {
      localStorage.setItem('token', data.access_token);
   
      return data;
    }))
  }

  logout(){
    return localStorage.removeItem('token');
    window.location.href = location.href;
  }

  createIndividualPerson(individualPerson: IndividualPerson): Observable<IndividualPerson>{
    return this.http.post<any>(`${environment.apiZoop}/marketplaces/a6700a86d21a43518a1ffc07c3d3a7e2/sellers/individuals`,individualPerson).pipe(map(data => {
      return data;
    }))
  }
  createLegalPerson(legalPerson: LegalPerson): Observable<LegalPerson>{
    return this.http.post<any>(`${environment.apiZoop}/marketplaces/a6700a86d21a43518a1ffc07c3d3a7e2/sellers/businesses`,legalPerson).pipe(map(data => {
      return data;
    }))
  }
  register(user: User): Observable<User>{
    return this.http.post<User>(`${environment.apiUrl}/auth/register`, user)
  }
  
  loggedInUser(): Observable<User[]>{
    return this.http.get<User[]>(`${environment.apiUrl}/usuario/logado`)
  }
}
