import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'walkthrough',
    loadChildren: () => import('./pages/walkthrough/walkthrough.module').then( m => m.WalkthroughPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate:[LoginGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./pages/forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs/faqs.module').then( m => m.FaqsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./pages/changepassword/changepassword.module').then( m => m.ChangepasswordPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'extract',
    loadChildren: () => import('./pages/extract/extract.module').then( m => m.ExtractPageModule)
  },
  {
    path: 'transfers',
    loadChildren: () => import('./pages/transfers/transfers.module').then( m => m.TransfersPageModule)
  },
  {
    path: 'simulatesales',
    loadChildren: () => import('./pages/simulatesales/simulatesales.module').then( m => m.SimulatesalesPageModule)
  },
  {
    path: 'type-register',
    loadChildren: () => import('./pages/type-register/type-register.module').then( m => m.TypeRegisterPageModule)
  },
  {
    path: 'legal-person-register',
    loadChildren: () => import('./pages/legal-person-register/legal-person-register.module').then( m => m.LegalPersonRegisterPageModule)
  },
  {
    path: 'sales',
    loadChildren: () => import('./pages/sales/sales.module').then( m => m.SalesPageModule)
  },
  {
    path: 'sales-link',
    loadChildren: () => import('./pages/sales-link/sales-link.module').then( m => m.SalesLinkPageModule)
  },
  {
    path: 'sales-plan',
    loadChildren: () => import('./pages/sales-plan/sales-plan.module').then( m => m.SalesPlanPageModule)
  },
  {
    path: 'account-bank',
    loadChildren: () => import('./pages/account-bank/account-bank.module').then( m => m.AccountBankPageModule)
  },  {
    path: 'transfer-account-bank',
    loadChildren: () => import('./pages/transfer-account-bank/transfer-account-bank.module').then( m => m.TransferAccountBankPageModule)
  },
  {
    path: 'sales-nfc',
    loadChildren: () => import('./pages/sales-nfc/sales-nfc.module').then( m => m.SalesNfcPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
